const About = () => {
  return (
    <>
      <div
        id="about"
        className="grid sm:grid-cols-2 grid-cols-1 sm:py-16 py-12 gap-10 2xl:container mx-auto xl:px-24 lg:px-10 px-5"
      >
        <div>
          <img src="/images/ig.jpeg" alt="" className="w-full" />
        </div>
        <div>
          <h2 className="xl:text-7xl md:text-5xl text-4xl font-bold font-grandstander text-primary uppercase">
            About Diamond
          </h2>
          <p className="mt-6 md:text-xl text-lg font-grandstander font-normal text-primary">
            Introducing Diamond, the heart of a revolutionary crypto project
            aiming to make a positive impact—Diamond. Diamond, inspired by
            Drake's beloved dog, embodies a vision of love, community, and
            philanthropy. Leading the charge with a bullish community, Diamond
            spearheads "Dogs Plan," a charitable movement dedicated to
            supporting dog charities and rescue centers.
          </p>
          <p className="mt-6 md:text-xl text-lg font-grandstander font-normal text-primary">
            Diamond's pawprint extends beyond the crypto realm, channeling the
            spirit of Drake's hit "God's Plan" into a canine-loving twist. With
            a commitment to spreading love and making a difference in the lives
            of dogs in need, Diamond envisions a brighter future for the entire
            dog community.
          </p>
          <p className="md:mt-12 mt-6 md:text-xl text-lg font-grandstander font-normal text-primary">
            Join Diamond and the Diamond community in this exciting journey,
            where every transaction contributes to a greater cause. Let's wag
            our way to positive change and make "Dogs Plan" a reality! 🐾💎
            #DiamondsDogsPlan #CryptoWithACause
          </p>
        </div>
      </div>
    </>
  );
};

export default About;

const Footer = () => {
  return (
    <>
      <div className="footer w-full border-t-4 rounded-[50px] flex lg:flex-nowrap flex-wrap items-center lg:justify-start justify-center gap-10 mt-10 2xl:container mx-auto xl:px-24 lg:px-10 px-5 py-10 relative">
        <div>
          <div className="flex justify-start">
            <img
              src="/images/logo.png"
              alt=""
              className="w-40 rounded-full mt-2"
            />
          </div>
          <div className="mt-8 flex gap-2 items-center">
            <a
              target="_blank"
              href="https://x.com/drakesdogSol"
              className="p-3 bg-gradient-to-b from-[#33DDA4] to-[#5A45C7] hover:bg-gradient-to-t rounded-full"
            >
              <img src="/svgs/twitter.svg" alt="" className="w-8" />
            </a>
            <a
              target="_blank"
              href="https://t.me/DrakesDogDiamondSOL"
              className="p-3 bg-gradient-to-b from-[#33DDA4] to-[#5A45C7] hover:bg-gradient-to-t rounded-full"
            >
              <img src="/svgs/telegram.svg" alt="" className="w-8" />
            </a>
            <a
              target="_blank"
              href=" https://www.tiktok.com/@drakesdogsol?_t=8jKZGwtB1W3&_r=1"
              className="p-3 bg-gradient-to-b from-[#33DDA4] to-[#5A45C7] hover:bg-gradient-to-t rounded-full"
            >
              <img src="/svgs/tiktok-icon.svg" alt="" className="w-8" />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/drakesdogsol/"
              className="p-3 bg-gradient-to-b from-[#33DDA4] to-[#5A45C7] hover:bg-gradient-to-t rounded-full"
            >
              <img src="/svgs/instagram-icon.svg" alt="" className="w-8" />
            </a>
          </div>
        </div>
        <div className="2xl:w-[36rem] xl:w-[30rem] w-[22rem]">
          <p className="text-base font-grandstander text-primary">
            Diamond is more than just a cryptocurrency, it is also a way to
            connect with other dog meme lovers and make a difference in the
            world. Join the Diamond movement today and help us make the world a
            more fun and inclusive place for everyone!
          </p>
        </div>
        <div>
          <img
            src="/images/img-1.png"
            alt=""
            className="lg:absolute -top-32 xl:right-0 -right-1 xl:w-[30rem] w-96"
          />
        </div>
      </div>
    </>
  );
};

export default Footer;

const OurGoal = () => {
  return (
    <>
      <div className="sm:py-20 flex items-center justify-center px-5">
        <div className="sm:w-[50rem] border-2 border-primary sm:p-8 p-5 rounded-[24px] flex sm:gap-8 items-start">
          <img src="/svgs/goal-icon.svg" alt="" />
          <div>
            <h4 className="text-xl font-grandstander font-normal text-primary">
              With Diamond, we have created a revolutionary digital currency that
              is designed for simplicity, security, and accessibility.
            </h4>
            <h4 className="text-xl font-grandstander font-normal text-primary mt-14">
              Our goal is to provide a seamless crypto experience for everyone.
              Whether you are an experienced trader or a curious beginner, Diamond
              is here to meet your needs and assist you in exploring the world
              of digital currencies.
            </h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurGoal;

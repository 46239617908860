const Roadmap = () => {
  return (
    <>
      <div id="roadmap" className="2xl:container mx-auto xl:px-24 lg:px-10 px-5 sm:py-20">
        <h2 className="xl:text-7xl md:text-5xl text-4xl font-grandstander font-bold text-primary">
          Roadmap
        </h2>
        <div className="grid sm:grid-cols-2 sm:mt-20 mt-12">
          <div className="w-full">
            <div className="bg-[#161616] rounded-2xl lg:px-12 px-5 py-10 relative">
              <div className="w-32 h-12 flex items-center justify-center rounded-2xl bg-[#79CEC5] font-grandstander font-medium text-xl absolute -top-5">
                Phase 1
              </div>
              <h2 className="sm:text-4xl text-2xl font-grandstander mt-8 text-white font-bold">
                Foundation and Planning
              </h2>
              <p className="mt-4 text-white font-grandstander sm:text-xl text-lg">
                Focuses on understanding the crypto market through detailed
                surveys, assembling a skilled AI and development team, and
                laying down solid product specifications.
              </p>
            </div>
            <div className="w-full h-2 rounded-[50px] bg-[#79CEC5]"></div>
          </div>
          <div className="flex items-end">
            <img src="/svgs/roadmap-right.svg" alt="" className="mt-40 sm:block hidden" />
          </div>
        </div>
        <div className="grid sm:grid-cols-2 sm:mt-10 mt-16">
          <div className="flex items-end justify-end">
            <img src="/svgs/roadmap-left.svg" alt="" className="mt-40 sm:block hidden" />
          </div>
          <div className="w-full">
            <div className="bg-[#161616] rounded-2xl lg:px-12 px-5 py-10 relative">
              <div className="w-32 h-12 flex items-center justify-center rounded-2xl bg-[#79CEC5] font-grandstander font-medium text-xl absolute -top-5">
                Phase 2
              </div>
              <h2 className="sm:text-4xl text-2xl font-grandstander mt-8 text-white font-bold">
                Development and Legal Setup
              </h2>
              <p className="mt-4 text-white font-grandstander sm:text-xl text-lg">
                Involves kicking off the software development, crafting a
                comprehensive whitepaper, designing the user interface,
                developing the onboarding flow, and completing legal formalities
                for company registration.
              </p>
            </div>
            <div className="w-full h-2 rounded-[50px] bg-[#79CEC5]"></div>
          </div>
        </div>
        <div className="grid sm:grid-cols-2 sm:mt-10 mt-16">
          <div className="w-full">
            <div className="bg-[#161616] rounded-2xl lg:px-12 px-5 py-10 relative">
              <div className="w-32 h-12 flex items-center justify-center rounded-2xl bg-[#79CEC5] font-grandstander font-medium text-xl absolute -top-5">
                Phase 3
              </div>
              <h2 className="sm:text-4xl text-2xl font-grandstander mt-8 text-white font-bold">
                Security and Marketing Preparation
              </h2>
              <p className="mt-4 text-white font-grandstander sm:text-xl text-lg">
                Concentrates on enhancing security by hiring a dedicated team,
                designing the landing page, creating a brand identity,
                developing the initial website, and strategizing for the market
                launch.
              </p>
            </div>
            <div className="w-full h-2 rounded-[50px] bg-[#79CEC5]"></div>
          </div>
          <div className="flex items-end">
            <img src="/svgs/roadmap-right.svg" alt="" className="mt-40 sm:block hidden" />
          </div>
        </div>
        <div className="grid sm:grid-cols-2 sm:mt-10 mt-16">
          <div className="flex items-end justify-end">
            <img src="/svgs/roadmap-left.svg" alt="" className="mt-40 sm:block hidden" />
          </div>
          <div className="w-full">
            <div className="bg-[#161616] rounded-2xl lg:px-12 px-5 py-10 relative">
              <div className="w-32 h-12 flex items-center justify-center rounded-2xl bg-[#79CEC5] font-grandstander font-medium text-xl absolute -top-5">
                Phase 4
              </div>
              <h2 className="sm:text-4xl text-2xl font-grandstander mt-8 text-white font-bold">
                Launch and Expansion
              </h2>
              <p className="mt-4 text-white font-grandstander sm:text-xl text-lg">
                Features critical steps like conducting audits, completing KYC,
                officially launching the Diamond currency and Type AI v1,
                developing key features, establishing partnerships, focusing on
                community engagement, and expanding accessibility.
              </p>
            </div>
            <div className="w-full h-2 rounded-[50px] bg-[#79CEC5]"></div>
          </div>
        </div>
        <div className="grid sm:grid-cols-2 sm:mt-10 mt-16">
          <div className="w-full">
            <div className="bg-[#161616] rounded-2xl lg:px-12 px-5 py-10 relative">
              <div className="w-32 h-12 flex items-center justify-center rounded-2xl bg-[#79CEC5] font-grandstander font-medium text-xl absolute -top-5">
                Phase 5
              </div>
              <h2 className="sm:text-4xl text-2xl font-grandstander mt-8 text-white font-bold">
                Advanced Developments
              </h2>
              <p className="mt-4 text-white font-grandstander sm:text-xl text-lg">
                Focuses on integrating advanced features like a Block Explorer,
                bridging capabilities, testing on various blockchain networks,
                adding onramp features, and integrating platforms for various
                DeFi activities.
              </p>
            </div>
            <div className="w-full h-2 rounded-[50px] bg-[#79CEC5]"></div>
          </div>
          <div className="flex items-end">
            <img src="/svgs/roadmap-right.svg" alt="" className="mt-40 sm:block hidden" />
          </div>
        </div>
        <div className="grid sm:grid-cols-2 sm:mt-10 mt-16">
          <div className="flex items-end justify-end">
            {/* <img src="/svgs/roadmap-left.svg" alt="" className="mt-40 sm:block hidden" /> */}
          </div>
          <div className="w-full">
            <div className="bg-[#161616] rounded-2xl lg:px-12 px-5 py-10 relative">
              <div className="w-32 h-12 flex items-center justify-center rounded-2xl bg-[#79CEC5] font-grandstander font-medium text-xl absolute -top-5">
                Phase 6
              </div>
              <h2 className="sm:text-4xl text-2xl font-grandstander mt-8 text-white font-bold">
                Diversification and Integration
              </h2>
              <p className="mt-4 text-white font-grandstander sm:text-xl text-lg">
                Aims at expanding Type AI's reach to multiple blockchain
                networks, developing specialized features like 'Type to Snipe',
                revamping the website for a better user experience, and building
                an SDK for external project integration.
              </p>
            </div>
            <div className="w-full h-2 rounded-[50px] bg-[#79CEC5]"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Roadmap;

import React from "react";
import CopyToClipboardButton from "../common/CopyToClipboardButton";

const tokenomics = [
  {
    id: 1,
    tokenName: "Symbol",
    tokenDesc: "DIAMOND",
  },
  {
    id: 2,
    tokenName: "Tax",
    tokenDesc: "0/0",
  },
  {
    id: 1,
    tokenName: "Liquidity",
    tokenDesc: "Burned Forever",
  },
];

const Tokenomics = () => {
  const renderTokenomics = tokenomics.map((item: any, index: number) => {
    return (
      <React.Fragment key={`${item.id}${index}`}>
        <div className="border-4 border-primary rounded-[24px] px-6 py-4 features-bg flex lg:flex-row flex-col items-center justify-between">
          <h3 className="mt-4 xl:text-4xl text-2xl font-bold font-grandstander text-primary">
            {item.tokenName}
          </h3>
          <p className="mt-4 font-grandstander xl:text-2xl text-lg font-normal text-primary lg:w-24">
            {item.tokenDesc}
          </p>
        </div>
      </React.Fragment>
    );
  });
  return (
    <>
      <div id="tokenomics" className="2xl:container mx-auto xl:px-24 lg:px-10 px-5 sm:py-20 py-10">
        <div className="flex sm:flex-row flex-col-reverse  sm:items-center items-start gap-5">
          <h2 className="xl:text-7xl md:text-5xl text-4xl font-grandstander font-bold text-primary">
            Tokenomics
          </h2>
          <img src="/images/tokenomics.png" alt="" />
        </div>
        <div className="grid sm:grid-cols-3 grid-cols-1 gap-6 sm:mt-20 mt-10">
          {renderTokenomics}
        </div>
        <div>
        
        </div>
        <div className="border-4 border-primary rounded-[24px] px-6 py-5 features-bg flex sm:flex-row flex-col items-center justify-between mt-6">
          <h3 className="mt-4 sm:text-4xl text-2xl font-bold font-grandstander text-primary">
            Token Address
          </h3>
          <CopyToClipboardButton textToCopy={'GoCr92MAhiE1kxXyGx7yPdS9qmVxhcJaKN7aNZumgcz6'} />
        </div>
      </div>
    </>
  );
};

export default Tokenomics;

import React, { useState, useEffect } from "react";
import * as Icons from "../common/Icons";

const Header = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  useEffect(() => {
    let prevScroll = window.pageYOffset;

    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const navbar = document.getElementById("navbar");

      if (!navbar) return;

      if (document.documentElement.scrollTop < 10) {
        navbar.style.backgroundColor = "transparent";
      } else {
        navbar.style.backgroundColor = "#171E26";
      }

      if (prevScroll > currentScrollPos) {
        navbar.style.top = "0";
      } else {
        navbar.style.top = "-150px";
        navbar.style.transition = "0.5s";
      }

      prevScroll = currentScrollPos;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const navLinks = [
    {
      id: 1,
      linkName: "Home",
      linkPath: "#home",
    },
    {
      id: 2,
      linkName: "About",
      linkPath: "#about",
    },
    {
      id: 3,
      linkName: "Tokenomics",
      linkPath: "#tokenomics",
    },
    {
      id: 4,
      linkName: "Roadmap",
      linkPath: "#roadmap",
    },
    {
      id: 5,
      linkName: "Socials",
      linkPath: "#socials",
    },
  ];

  const renderNavLinks = navLinks.map((items: any, index: number) => {
    return (
      <React.Fragment key={`${items.id}${index}`}>
        <a onClick={() => setShowMenu(false)} href={items.linkPath}>
          <p className="font-grandstander xl:text-xl lg:text-base text-3xl font-semibold text-white hover:underline underline-offset-8">
            {items.linkName}
          </p>
        </a>
      </React.Fragment>
    );
  });
  return (
    <>
      <div
        id="navbar"
        className="2xl:container mx-auto xl:px-24 lg:px-10 px-5 py-3 fixed top-0 w-full inset-x-0 flex items-center lg:justify-start justify-between bg-black/90 z-50 gap-10"
      >
        <a href="/" className="lg:w-[20%] flex items-center">

            <img
              src="/images/logo.png"
              alt=""
              className="w-28 rounded-full mt-2"
            />

        </a>
        <div className="w-4/5 flex items-center justify-between gap-10">
          <div className="lg:flex hidden items-center justify-between w-full">
            {renderNavLinks}
          </div>
          <a
            href="#socials"
            className="font-grandstander xl:text-xl text-base text-white hover:bg-primary hover:text-black font-semibold w-[22rem] h-16 border border-white rounded-[24px] lg:flex hidden items-center justify-center"
          >
            Join the community
          </a>
        </div>
        {/* Mobile view */}
        <div className="lg:hidden">
          <div className="text-white">
            {showMenu ? (
              <div onClick={() => setShowMenu(false)}>
                <Icons.CloseIcon />
              </div>
            ) : (
              <div onClick={() => setShowMenu(true)}>
                <Icons.HamburgerMenu />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Mobile Menu */}
      <div
        className={`fixed top-0 lg:hidden inset-x-0 bg-black/90 transition-all ease-in-out duration-500 overflow-hidden sm:px-10 px-5 ${
          showMenu ? "py-40 h-full" : "py-0 h-0"
        } z-40`}
      >
        <div className="flex flex-col gap-4">{renderNavLinks}</div>
        <a onClick={() => setShowMenu(false)} href="#socials">
          <button className="font-grandstander xl:text-xl text-base text-white font-semibold py-4 px-8 border border-white rounded-[24px] lg:hidden mt-5">
            Join the community
          </button>
        </a>
      </div>
    </>
  );
};

export default Header;

import { useState } from "react";
import * as Icons from "../common/Icons";
import EthButton from "../common/EthButton";

const BuyUsingOrca = () => {
  const [showEth, setShowEth] = useState<boolean>(false);
  return (
    <>
      <div className="flex md:flex-row flex-col justify-between items-center 2xl:container mx-auto xl:px-24 lg:px-10 px-5 py-16 lg:gap-0 gap-3">
        <div className="h-full">
          <h2 className="xl:text-7xl lg:text-5xl text-4xl font-grandstander font-bold text-primary">
            Or buy using <br /> Orca:
          </h2>
          <p className="mt-6 text-xl font-grandstander font-normal text-primary xl:w-auto lg:w-96">
            Want to buy cross-chain? Swap seamlessly from ETH, BSC, ARB,{" "}
            <br className="xl:block hidden" /> AVAX or Solana chain to buy
            Diamond. Low fees and cross chain.
          </p>
        </div>
        <div className="sm:w-auto w-full">
          <div className="sm:w-96 w-full buy-myro py-6 px-4 rounded-[25px] relative">
            <div className="flex items-center justify-between">
              <div className="flex gap-3 items-center">
                <p className="text-xl font-bold text-primary font-grandstander">
                  Buy Diamond
                </p>
              </div>
              <div className="bg-[rgb(68,_73,_91)] p-2 text-white rounded-lg">
                <Icons.SettingIcon />
              </div>
            </div>
            <div className="py-5 w-full bg-[rgba(0,_0,_0,_0.2)] border border-gray-600 mt-4 rounded-[24px] px-4">
              <div className="flex items-center justify-between">
                <div
                  onClick={() => setShowEth(true)}
                  className="rounded-[20px] bg-[#44495B] flex gap-3 px-3 py-2 cursor-pointer"
                >
                  <img src="/images/myro.png" alt="" className="w-7" />
                  <div className="flex items-center gap-2">
                    <h5 className="text-sm text-white font-light">ETH </h5>
                    <Icons.DownArrowIcon />
                  </div>
                </div>
                <EthButton />
              </div>
              <div className="px-3">
                <p className="mt-3 text-[#424855] text-2xl">0.00</p>
              </div>
            </div>
            <div className="py-5 w-full bg-[rgba(0,_0,_0,_0.2)] border border-gray-600 mt-2 rounded-[24px] px-4">
              <div className="flex items-center justify-between">
                <div className="rounded-[20px] bg-[#44495B] flex gap-3 px-3 py-2">
                  <img src="/images/myro.png" alt="" className="w-7" />
                  <div className="flex items-center gap-2">
                    <h5 className="text-sm text-white font-light">Diamond </h5>
                  </div>
                </div>
                <div className="rounded-[20px] bg-[#44495B] flex gap-3 px-3 py-2">
                  <img src="/images/salana.png" alt="" className="w-7" />
                  <div className="flex items-center gap-2">
                    <h5 className="text-sm text-white font-light">Solana </h5>
                  </div>
                </div>
              </div>
              <div className="px-3">
                <p className="mt-3 text-[#424855] text-2xl">0.00</p>
              </div>
            </div>
            <a href="https://www.orca.so/">
              <button className="py-4 w-full bg-[rgb(47,_152,_249)] hover:bg-[#216AAE] mt-4 rounded-[50px] text-white font-semibold text-base">
                Connect Wallet
              </button>
            </a>
            {/* ETH Popup */}
            <div
              className={`absolute top-0 h-full w-full bg-[#3A3B44] inset-x-0 rounded-xl py-5 px-5 ${
                showEth ? "block" : "hidden"
              }`}
            >
              <div className="flex justify-between item-center w-full">
                <h3 className="text-base text-white font-bold font-urbanist">
                  Select Token
                </h3>
                <div
                  onClick={() => setShowEth(false)}
                  className="text-white cursor-pointer"
                >
                  <Icons.CloseIconSm />
                </div>
              </div>
              <div className="flex items-center h-14 mt-4 w-full bg-[#1B1B1E] rounded-2xl px-4">
                <input
                  type="text"
                  placeholder="Search name and paste address"
                  className="h-full w-full bg-transparent focus:border-0 focus:outline-none focus:ring-0 text-white font-urbanist"
                />
              </div>
              <div className="h-[76%] overflow-y-auto">
                <div className="border border-gray-500 mt-5 rounded-2xl">
                  <div className="flex items-center gap-3 px-5 h-14 rounded-2xl">
                    <div className="w-10">
                      <img
                        src="https://statics.mayan.finance/eth.png"
                        alt=""
                        className="w-8"
                      />
                    </div>
                    <div>
                      <h3 className="text-sm text-white font-medium">ETH</h3>
                      <p className="text-gray-500 text-xs">ETH</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-5 px-5 h-14 rounded-2xl">
                    <div className="w-10">
                      <img
                        src="https://assets.coingecko.com/coins/images/7598/small/wrapped_bitcoin_wbtc.png?1548822744"
                        alt=""
                        className="w-8"
                      />
                    </div>
                    <div>
                      <h3 className="text-sm text-white font-medium">WPTC</h3>
                      <p className="text-gray-500 text-xs">Wrapped Bitcoin</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-5 px-5 h-14 rounded-2xl">
                    <div className="w-10">
                      <img
                        src="https://assets.coingecko.com/coins/images/9956/small/4943.png?1636636734"
                        alt=""
                        className="w-8"
                      />
                    </div>
                    <div>
                      <h3 className="text-sm text-white font-medium">DAI</h3>
                      <p className="text-gray-500 text-xs">dai</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-5 px-5 h-14 rounded-2xl">
                    <div className="w-10">
                      <img src="/images/usdc.png" alt="" className="w-8" />
                    </div>
                    <div>
                      <h3 className="text-sm text-white font-medium">USDC</h3>
                      <p className="text-gray-500 text-xs">0.00000</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-5 px-5 h-14 rounded-2xl">
                    <div className="w-10">
                      <img src="/images/usdc.png" alt="" className="w-8" />
                    </div>
                    <div>
                      <h3 className="text-sm text-white font-medium">USDC</h3>
                      <p className="text-gray-500 text-xs">0.00000</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-5 px-5 h-14 rounded-2xl">
                    <div className="w-10">
                      <img src="/images/usdc.png" alt="" className="w-8" />
                    </div>
                    <div>
                      <h3 className="text-sm text-white font-medium">USDC</h3>
                      <p className="text-gray-500 text-xs">0.00000</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-5 px-5 h-14 rounded-2xl">
                    <div className="w-10">
                      <img src="/images/usdc.png" alt="" className="w-8" />
                    </div>
                    <div>
                      <h3 className="text-sm text-white font-medium">USDC</h3>
                      <p className="text-gray-500 text-xs">0.00000</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyUsingOrca;

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

const CopyToClipboardButton: React.FC<{ textToCopy: string }> = ({
  textToCopy,
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = async () => {
    try {
      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;

      document.body.appendChild(textarea);
      textarea.select();

      const success = document.execCommand("copy");

      document.body.removeChild(textarea);

      if (success) {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      } else {
        console.error("Unable to copy text to clipboard.");
      }
    } catch (error) {
      console.error("Error copying text to clipboard:", error);
    }
  };

  return (
    <div>
      <button onClick={handleCopyClick} className="text-white flex items-center gap-3">
        <p className="mt-4 font-grandstander sm:text-2xl text-lg font-normal text-primary truncate sm:w-80 w-60">
          {isCopied
            ? "Copied!"
            : "GoCr92MAhiE1kxXyGx7yPdS9qmVxhcJaKN7aNZumgcz6"}{" "}
        </p>
        <img src="/svgs/copy-icon.svg" alt="" />
      </button>
      {/* <div className="flex items-center gap-3">
        <p className="mt-4 font-grandstander sm:text-2xl text-lg font-normal text-primary">
          HhJpBhRRn4g56VsyLuT...
        </p>
        <img src="/svgs/copy-icon.svg" alt="" />
      </div> */}
    </div>
  );
};

export default CopyToClipboardButton;

const FollowUs = () => {
  return (
    <>
      <div
        id="socials"
        className="2xl:container mx-auto xl:px-24 lg:px-10 px-5 sm:py-20 py-10 grid sm:grid-cols-2 grid-cols-1 xl:gap-0 gap-10"
      >
        <img src="/svgs/follow-us.svg" alt="" />
        <div>
          <h2 className="xl:text-7xl lg:text-5xl text-4xl font-grandstander font-bold text-primary">
            Follow Diamond on social media
          </h2>
          <div className="mt-8 flex gap-6 items-center">
            <a
              target="_blank"
              href="https://x.com/drakesdogSol"
              className="p-3 bg-gradient-to-b from-[#33DDA4] to-[#5A45C7] hover:bg-gradient-to-t rounded-full"
            >
              <img src="/svgs/twitter.svg" alt="" className="w-12" />
            </a>
            <a
              target="_blank"
              href="https://t.me/DrakesDogDiamondSOL"
              className="p-3 bg-gradient-to-b from-[#33DDA4] to-[#5A45C7] hover:bg-gradient-to-t rounded-full"
            >
              <img src="/svgs/telegram.svg" alt="" className="w-12" />
            </a>
            <a
              target="_blank"
              href=" https://www.tiktok.com/@drakesdogsol?_t=8jKZGwtB1W3&_r=1"
              className="p-3 bg-gradient-to-b from-[#33DDA4] to-[#5A45C7] hover:bg-gradient-to-t rounded-full"
            >
              <img src="/svgs/tiktok-icon.svg" alt="" className="w-12" />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/drakesdogsol/"
              className="p-3 bg-gradient-to-b from-[#33DDA4] to-[#5A45C7] hover:bg-gradient-to-t rounded-full"
            >
              <img src="/svgs/instagram-icon.svg" alt="" className="w-12" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default FollowUs;

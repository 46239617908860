import About from "./About";
import BuyUsingJupiter from "./BuyUsingJupiter";
import BuyUsingOrca from "./BuyUsingOrca";
import Features from "./Features";
import FollowUs from "./FollowUs";
import HowToBuy from "./HowToBuy";
import OurGoal from "./OurGoal";
import Roadmap from "./Roadmap";
import Tokenomics from "./Tokenomics";
import WhyChooseUs from "./WhyChooseUs";

const Home = () => {
  return (
    <>
      {/*  */}
      <div
        id="home"
        className="flex items-center 2xl:container mx-auto xl:px-24 lg:px-10 px-5 sm:h-screen xl:pt-20 sm:pt-32 pt-44"
      >
        <div className="xl:w-[45%] lg:w-3/5 md:w-3/4 w-full">
          <h2 className="font-grandstander text-primary sm:text-7xl text-4xl font-bold">
            Drake’s Dog -
          </h2>
          <h2 className="font-black italic banner-text font-grandstander uppercase mt-3 sm:text-[108px] text-6xl">
            Diamond
          </h2>
          <h6 className="text-xl font-grandstander text-primary font-normal mt-4">
            Embark on a unique journey with Diamond - a fusion of Shiba charisma
            and Shmoney magic inspired by none other than Drake’s lovable
            companion, <strong>Diamond!</strong>
          </h6>
          <div className="grid sm:grid-cols-2 grid-cols-1 mt-5">
            <a
              href="https://jup.ag/swap/DIAMOND_GoCr92MAhiE1kxXyGx7yPdS9qmVxhcJaKN7aNZumgcz6-SOL"
              className="sm:w-60 w-full h-16 bg-primary rounded-[20px] font-grandstander font-medium sm:text-xl text-base flex items-center justify-center gap-3 hover:border-2 border-white hover:text-white hover:bg-transparent "
            >
              Buy on Jupiter
              <img src="/svgs/buy-jupiter-icon.svg" alt="" />
            </a>
          </div>
        </div>
        <div className="xl:w-[55%] w-1/2 lg:block hidden">
          <img src="/images/img-1.png" alt="" className="w-full" />
        </div>
      </div>
      {/* <Currencies /> */}
      <About />
      <OurGoal />
      <HowToBuy />
      <BuyUsingJupiter />
      <BuyUsingOrca />
      <Features />
      <Tokenomics />
      <Roadmap />
      <FollowUs />
      <WhyChooseUs />
    </>
  );
};

export default Home;
